import React, { useEffect, useState } from "react"

import styled, { css, keyframes } from "styled-components"

const SvgArrow = () => (
  <svg width="1.7rem" height="1.7rem" viewBox="0 0 8 7">
    <path
      d="M 1 5, L 4 2, L 7 5" // "M1 5, L4 2, L7 5, M4 2, L4 9"
      fill="transparent"
      stroke="white"
      strokeWidth="1.75"
      style={{ stroke: `var(--color-primary-02)` }}
    />
  </svg>
)

const Div = styled.div`
  background: no-repeat
    radial-gradient(
      circle at bottom,
      var(--color-shock-1) 10%,
      var(--color-shock-3) 100%
    );
  padding: 0.75rem;
  clip-path: circle(40% at 50% 50%);
  display: flex;

  transition: 0.15s transform ease;
`

const animation = keyframes`
  0% {
    filter: brightness(0.9);
  }
  30% {
    filter: brightness(0.9);
  }
  55% {
    filter: brightness(1.2);
  }
  90% {
    filter: brightness(0.9);
  }
  100% {
    filter: brightness(0.9);
  }
`

const animationRule = css`
  ${animation} 2s infinite ease-in-out;
`

const DivWrapper = styled.div`
  position: fixed;
  bottom: 2rem;
  z-index: 1;
  left: calc(50vw - 1.5rem);

  ${props => {
    if (!props.scrolling) {
      return css`
        transition: 0.5s opacity ease;
        opacity: 0%;
      `
    }
    return css`
      transition: 2s opacity, transform ease;
      opacity: 100%;

      animation: ${animationRule};

      &:hover > Div {
        transform: translate(0, -0.2rem);
        cursor: pointer;
      }
    `
  }}
`

const UpLink = () => {
  const [scrolling, setIsScrolling] = useState(undefined)
  const minScrollingYOffset = 200

  useEffect(() => {
    const on_scroll = () => {
      setIsScrolling(minScrollingYOffset < window.pageYOffset)
    }
    window.addEventListener("scroll", on_scroll)
    return () => window.removeEventListener("scroll", on_scroll)
  })

  const scrollTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <DivWrapper scrolling={scrolling}>
      <Div onClick={scrollTop}>
        <SvgArrow />
      </Div>
    </DivWrapper>
  )
}

export default UpLink
