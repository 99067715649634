import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Logo from "../Logo/logo"

const HeaderBar = styled.div`
  background: no-repeat
    linear-gradient(
      0deg,
      ${props => props.background_color_1} 45%,
      ${props => props.background_color_2}
    );

  position: sticky;
  top: 0rem;
  padding: 1rem 0 6rem 0;

  z-index: 1;

  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex-direction: column;

  @media (max-width: 50rem) {
    padding: 1rem 0;
    z-index: 10;
  }
`

const TranslateToLeft = styled.div`
  transition: 0.1s all ease;
  transform: translate(-80%, 0);

  @media only screen and (max-width: ${props => props.max_width}) {
    transform: translate(0, 0);
  }
`

const Header = ({ background_color_1, background_color_2 }) => (
  <>
    <div
      style={{
        // display: `flex`,
        paddingTop: `7rem`,
        backgroundColor: `${background_color_2}`,
        zIndex: 1,
      }}
    />
    <HeaderBar
      id="header"
      background_color_1={background_color_1}
      background_color_2={background_color_2}
    >
      <Link to={"/"}>
        <TranslateToLeft max_width="66rem">
          <Logo
            font_size="2.75rem"
            // primary_color="var(--color-primary-03)"
            primary_color="var(--color-primary-13)"
            secondary_color="var(--color-shock-3)"
            tertiary_color="transparent"
            border_color="transparent"
          />
        </TranslateToLeft>
      </Link>
    </HeaderBar>
  </>
)

export default Header

/*
const TitleLink = styled(Link)`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 2.35rem;
  font-size: 3rem;

  padding: 0.5rem 0rem;
  margin: 0em 0em 1.2em 0em;
  white-space: nowrap;

  color: hsl(0, 0%, 95%);
  letter-spacing: 1px;

  transition: 0.5s all ease;
  transition-property: all;

  &:active,
  &:hover {
    transform: translate(-16px, -3px) scale(1.1);
    transform: translate(0px, 0px) scale(1.1);
    transition-duration: 1.5s;
  }
`
*/
