import React from "react"
import styled from "styled-components"

import Logo from "../Logo/logo"

const EmailLink = styled.a`
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  color: var(--color-primary-13);

  &:active,
  &:hover {
    outline-width: 0;
    color: var(--color-shock-2);
    // text-decoration: underline;
  }
`

const GatsbyLink = styled.a`
  color: hsl(278, 40%, 49%);
  opacity: 50%;

  &:hover {
    opacity: 100%;
  }
`

const FooterBlock = styled.div`
  display: flex;
  padding: 3rem 6rem 0rem 6rem;
  flex-direction: ${props => props.flex_direction || "column"};
  justify-content: flex-end;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxyge; Ubuntu, antarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  font-family: nunito sans;
  margin: 0.1rem;
`

const P = styled.div`
  margin: 0 0 var(--default-margin) 0;

  padding: 0;

  line-height: 2rem;
  font-family: nunito sans;

  font-size: 0.9rem;
  min-width: 14rem;
`

const StyledFooter = styled.div`
  display: flex;
  // min-width: 25rem;

  padding: 4rem 0 2rem 0;
  background-color: ${props => props.background_color};

  justify-content: center;

  flex-direction: row;
  // flex-flow: row wrap;

  @media (min-width: 40rem) and (max-width: 60rem) {
    & > ${FooterBlock} {
      // color: green;
      padding: 2rem;
    }
  }

  @media (min-width: 0rem) and (max-width: 40rem) {
    & > ${FooterBlock} {
      // color: red;
      width: 40vw;
      padding: 3rem 2rem 0rem 2rem;
    }
    padding: 4rem 0 2rem 0;
    flex-direction: column;
    justify-content: flex-start;
  }
`

const Footer = ({ background_color }) => (
  <StyledFooter background_color={background_color}>
    <FooterBlock>
      <P>
        <Logo
          secondary_color="var(--color-shock-3)"
          font_size="1.2rem"
          justify_content="flex-start"
          // line_height="1.2rem"
          line_height="1em"
          toggle_mobile_width=""
        />
      </P>
      <P>
        Vanha Kaarelantie 2 B 55
        <br />
        01610 VANTAA
      </P>
      <P>
        Email:{"  "}
        <EmailLink href="mailto:team.immonen@gmail.com">
          team.immonen@gmail.com
        </EmailLink>
        <br />
        Phone: +358 50 511 1092
        <br />
        Business ID: 3183833-2
      </P>
    </FooterBlock>

    <FooterBlock>
      <div style={{ lineHeight: `2rem`, width: `11rem` }}>
        Team Immonen © {new Date().getFullYear()}
        {` `}
      </div>
      <div
        style={{ lineHeight: `2rem`, width: `11rem`, marginBottom: `2.45rem` }}
      >
        <GatsbyLink href="https://www.gatsbyjs.com">
          Built with Gatsby
        </GatsbyLink>
      </div>
    </FooterBlock>
  </StyledFooter>
)
/*<FooterBar bg_color="var(--color-primary-03)">
</FooterBar>*/

export default Footer

// fontFamily: `georgia, serif`
