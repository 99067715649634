import React from "react"
import styled from "styled-components"

const LogoTextSharedStyle = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: ${props => props.font_size};
  letter-spacing: 1px;

  display: flex;
  padding: 3.363% 3.363% 5.38% 4.035%;

  line-height: ${props => props.line_height};

  @media only screen and (max-width: ${props => props.toggle_mobile_width}) {
    font-size: calc(${props => props.font_size} * 0.75);
  }
`

const LogoTextPrefix = styled(LogoTextSharedStyle)`
  font-weight: 700;

  color: ${props => props.primary_color};
  background: no-repeat
    radial-gradient(ellipse, var(--color-shock-1) 0%, var(--color-shock-5) 70%);

  border: 2px solid var(--color-shock-5);
  border-radius: 0.15rem;
`

const LogoTextSuffix = styled(LogoTextSharedStyle)`
  font-weight: 650;

  border: 2px solid ${props => props.border_color};

  color: ${props => props.secondary_color};
  background-color: ${props => props.border_color};
`

const Logo = ({
  font_size,
  primary_color,
  secondary_color,
  tertiary_color = "transparent",
  border_color = "transparent",
  justify_content = "center",
  line_height = "",
  toggle_mobile_width = "415px",
}) => {
  return (
    <div
      style={{
        flexDirection: `row`,
        justifyContent: justify_content,
        alignItems: `center`,
        display: `inline-flex`,
      }}
    >
      <LogoTextPrefix
        font_size={font_size}
        primary_color={primary_color}
        secondary_color={secondary_color}
        line_height={line_height}
        toggle_mobile_width={toggle_mobile_width}
      >
        Team
      </LogoTextPrefix>
      <LogoTextSuffix
        font_size={font_size}
        primary_color={primary_color}
        secondary_color={secondary_color}
        tertiary_color={tertiary_color}
        border_color={border_color}
        toggle_mobile_width={toggle_mobile_width}
        line_height={line_height}
      >
        Immonen
      </LogoTextSuffix>
    </div>
  )
}

export default Logo
