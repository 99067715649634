import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  :root {
    --color-shock-2: hsl(80, 30%, 40%);
    --color-primary: hsl(0, 0%, 18%);
    --color-background-primary: hsl(0, 0%, 100%);
    --color-background-secondary: hsl(0, 0%, 90%);
  }
`

// --color-shock-2: hsl(80, 30%, 40%);
const GlobalStyleDark = createGlobalStyle`
  :root {
    --color-shock-1: hsl(100, 65%, 40%);
    --color-shock-2: hsl(100, 45%, 55%);
    --color-shock-3: hsl(100, 60%, 60%);
    --color-shock-4: hsl(80, 60%, 40%);
    --color-shock-5: hsl(100, 45%, 50%);

    --color-accent: rgb(40, 60, 140);
    --color-valid: hsl(100, 45%, 45%);
    --color-invalid: hsl(0, 60%, 55%);

    --color-primary-00: hsl(0, 0%, 0%);
    --color-primary-01: hsl(0, 0%, 4%);
    --color-primary-02: hsl(0, 0%, 8%);
    --color-primary-03: hsl(0, 0%, 16%);
    --color-primary-04: hsl(0, 0%, 20%);
    --color-primary-05: hsl(0, 0%, 25%);
    --color-primary-06: hsl(0, 0%, 30%);
    --color-primary-07: hsl(0, 0%, 40%);
    --color-primary-08: hsl(0, 0%, 50%);
    --color-primary-09: hsl(0, 0%, 60%);
    --color-primary-10: hsl(0, 0%, 70%);
    --color-primary-11: hsl(0, 0%, 80%);
    --color-primary-12: hsl(0, 0%, 90%);
    --color-primary-13: hsl(0, 0%, 95%);
    --color-primary-14: hsl(0, 0%, 100%);
  }
`

export { GlobalStyle, GlobalStyleDark }
