import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const NavBarDiv = styled.div`
  background-color: ${props => props.background_color || "transparent"};

  position: sticky;
  top: 6rem;

  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin: 0;

  font-weight: 550;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;

  display: flex;
  flex-direction: ${props => props.flex_direction};
  z-index: 2;

  @media (max-width: ${props => props.max_width}) {
    position: relative;
    padding: 4rem 0 0rem 0;
    top: 0rem;
    flex-direction: ${props => props.flex_direction_alternative};
  }
`

const NavBarLink = styled(Link)`
  color: ${props => props.color || "var(--color-primary-12)"};
  background-color: ${props => props.bg_color || "transparent"};

  transition-property: all;
  transition: 0.5s all ease;

  margin: 0 0 0 0;
  border-bottom: 3px solid transparent;
  padding: 1.5rem 0;
  width: 14rem;

  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  &:active,
  &:focus,
  &:hover {
    transition: 0.25s all ease;
    color: ${props => props.hover_color || "hsl(0, 0%, 20%)"};
    background-color: ${props => props.hover_bg_color || "hsl(0, 0%, 90%)"};
    border-bottom: 3px solid ${props => props.underline_color || "transparent"};
  }

  @media (max-width: ${props => props.max_width}) {
    width: 100%;
  }
`

// { url: "/about_me", name: "Mikä Team Immonen?" },
const sites = [
  { url: "/services", name: "Palvelut" },
  { url: "/pricing", name: "Hinnasto" },
  { url: "/about_me", name: "Kuka olen?" },
  { url: "/contact", name: "Ota yhteyttä" },
]

const NavigationBar = ({ background_color }) => {
  // background_color = "var(--color-parimary-02)"
  // background_color = "var(--color-primary-01)"
  return (
    <>
      <NavBarDiv
        background_color={background_color}
        // min_width=""
        flex_direction="row"
        max_width="50rem"
        flex_direction_alternative="column"
      >
        {sites.map(site => (
          <NavBarLink
            to={site.url}
            key={site.name}
            bg_color="transparent"
            color="var(--color-primary-12)"
            hover_color="var(--color-shock-3)" // var(--color-shock-4)
            hover_bg_color={background_color}
            underline_color="var(--color-shock-1)" // var(--color-shock-4)
            max_width="50rem"
          >
            {site.name}
          </NavBarLink>
        ))}
      </NavBarDiv>
    </>
  )
}

export default NavigationBar

/*
      <NavBarDiv>
        {sites.map(site => (
          <NavBarLink
            to={site.url}
            key={site.name}
            hover_color="hsl(1, 0%, 15%)"
            hover_bg_color="hsl(0, 0%, 90%)"
          >
            {site.name}
          </NavBarLink>
        ))}
      </NavBarDiv>

      <NavBarDiv bg_color="hsl(80, 30%, 40%)">
        {sites.map(site => (
          <NavBarLink
            to={site.url}
            key={site.name}
            color="hsl(0, 0%, 95%)"
            hover_color="hsl(0, 0%, 100%)"
            hover_bg_color="hsl(80, 30%, 50%)"
          >
            {site.name}
          </NavBarLink>
        ))}
      </NavBarDiv>

      <NavBarDiv bg_color="var(--color-primary-06)">
        {sites.map(site => (
          <NavBarLink
            to={site.url}
            key={site.name}
            hover_color="hsl(0, 0%, 15%)"
            hover_bg_color="hsl(0, 0%, 95%)"
          >
            {site.name}
          </NavBarLink>
        ))}
      </NavBarDiv>
*/
