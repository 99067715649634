/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import Header from "../Header/header"
import Footer from "../Footer/footer"
import NavigationBar from "../NavigationBar/navigation_bar"

import { GlobalStyleDark } from "../global_style"

import UpLink from "../UpLink/up_link"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const background_color_1 = "var(--color-primary-01)"
  const background_color_2 = "var(--color-primary-01)"

  return (
    <>
      <GlobalStyleDark />
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        background_color_1={background_color_1}
        background_color_2={background_color_2}
      />
      <NavigationBar background_color={background_color_1} />
      <UpLink />
      <main
        style={{
          display: `flex`,
          flexDirection: `column`,
          // alignItems: `stretch`,
        }}
      >
        {children}
      </main>
      <Footer background_color={background_color_1} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
